@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import "variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  text-decoration: none;
}
.homePageNav {
  z-index: 10;
  position: fixed;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid #55555555;
  transition: 0.2s ease-in-out;
  .navSafeWidth {
    margin: 0 auto;
    max-width: 1440px;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  a {
    display: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #960018;
  }
  a:hover {
    border: 0px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    color: #ffffff;
    background-color: #960018;
  }

  .active {
    border: 0px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    color: #ffffff;
    background-color: #960018;
  }

  .selected {
    border: 5px solid red;
  }

  img {
    max-height: 2.7rem;
  }
  .mobileMenuOpenButton {
    display: inline;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.mobileMenu {
  z-index: 15;
  background-color: #efefef;
  width: 70vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    font-size: 1.3rem;
    margin: 2rem;
    text-decoration: none;
  }
  .logo {
    width: 60vw;
  }
}

.googleMaps {
  iframe {
    height: 230px;
    width: 350px;
  }
}

.whereWeAre {
  background-color: #960018;
  padding: 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    h2 {
      color: #ffffff;
      font-family: "Poppins", Sans-serif;
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    h3 {
      color: #ffffff;
      font-family: "Poppins", Sans-serif;
      font-weight: 800;
      font-size: 25px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}

.footer {
  margin: 0 auto;
  max-width: 1440px;
  margin-top: 1rem !important;

  p {
    a {
      color: black;
    }
  }

  h1 {
    color: #960018;
  }

  h2 {
    a {
      color: #960018;
    }
  }

  .footerItem:nth-child(1) {
    display: none;
  }

  .footerItem {
    padding: 0 1rem;
    margin-bottom: 2rem;
    .locationFooter {
      margin-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      h2 {
        font-size: 1rem;
        font-weight: 500;
        margin: 1rem 1rem;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .socialNetworks {
      margin-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      h2 {
        font-size: 1rem;
        font-weight: 500;
        margin: 1rem 1rem;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  h1 {
    margin: 1rem auto;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
}

.homePage {
  padding-top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h2 {
    color: #960018;
    font-family: "Poppins", Sans-serif;
    font-weight: 800;
    font-size: 30px;
  }

  h3 {
    color: rgba(12, 12, 12, 0.11);
    font-family: "Poppins", Sans-serif;
    font-size: 40px;
    font-weight: 800;
  }

  p {
    color: #0c0c0c;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8em;
    letter-spacing: 0.1px;
  }

  b {
    letter-spacing: 0.7px;
  }

  .droneVideo {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    padding-bottom: 2rem;

    h2 {
      color: #960018;
      font-family: "Poppins", Sans-serif;
      font-weight: 800;
      padding-top: 1rem;
    }

    p {
      display: none;
    }

    video {
      width: 320px;
      height: 240px;
    }
  }

  .homePageGalleryWrapper {
    background-color: #fff;

    .homePageGallery {
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      align-items: center;
      max-width: 800x;
      background-color: #fff;

      .homePageGalleryMobile {
        padding-top: 1rem;
        margin-bottom: 1rem;
        h2 {
          display: inline;
        }
      }

      .imageGallery {
        max-width: 75%;
      }

      h2 {
        color: #960018;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        padding-top: 1rem;
        display: none;
      }

      p {
        display: none;
      }
    }
  }

  .backgroundColor {
    background-color: #ffffff;
  }

  .aboutUs {
    background-color: #ffffff;
    margin: 2rem auto;
    max-width: 800px;
    align-items: center;
    padding: 0 1rem;

    p {
      text-align: center;
    }
  }

  .galleryRedirectWrapper {
    background-color: #fff;

    .galleryRedirect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;
      background-color: #fff;

      .restaurantGallery {
        width: 70%;
        position: relative;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        p {
          color: #fff;
          font-weight: bold;
          font-style: italic;
          font-size: 25px;
          letter-spacing: 4px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        img {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: auto;
          border-radius: 12px;
        }
      }

      .mobileGalleryText {
        h2 {
          color: #960018;
          font-family: "Poppins", Sans-serif;
          font-weight: 800;
        }
      }

      .galleryText {
        display: none;
        flex-direction: row;

        h2 {
          color: #960018;
          font-family: "Poppins", Sans-serif;
          font-weight: 800;
        }
      }

      .roomsGallery {
        width: 70%;
        position: relative;

        p {
          color: #fff;
          font-weight: bold;
          font-style: italic;
          font-size: 25px;
          letter-spacing: 4px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: auto;
          border-radius: 12px;
        }
      }
    }
  }

  .contactUs {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: auto;
    margin-top: 1rem;

    p {
      color: #960018;
      font-family: "Poppins", Sans-serif;
      font-weight: 800;
      margin-top: 0.5rem;
      margin-bottom: 0.2rem;
    }

    b {
      a {
        color: black;
      }
    }

    .contactUsTitle {
      margin-bottom: 1.5rem;
      h2 {
        color: #960018;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        font-size: 25px;
      }
    }

    .contactUsItems {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .phoneNumber {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        margin-bottom: 1rem;

        .phoneIcon {
          padding: 1.5rem;
          border-style: solid;
          border-radius: 50%;
          border-width: 0.3rem;
          border-color: #960018;
        }

        .phoneText {
          padding-left: 3rem;

          p {
            font-size: 23px;
          }

          a {
            font-size: px;
          }
        }
      }
    }
  }
}

.restaurant {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .restaurantTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }

  .description {
    max-width: 1080px;
    margin: 0 auto;
    padding: 1rem;
    justify-content: center;

    p {
      padding: 1rem;
      color: #0c0c0c;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0.1px;
      text-align: justify;
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 90%;
  }
}

.rooms {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .roomsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }

  .description {
    max-width: 1080px;
    margin: 0 auto;
    padding: 1rem;
    justify-content: center;

    p {
      padding: 1rem;
      color: #0c0c0c;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0.1px;
      text-align: justify;
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 90%;
  }
}

.places {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  img {
    width: 100%;
  }

  h3 {
    text-align: center;
  }

  .toVisitTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }

  // .descriptionStyle {
  //   max-width: 450px;
  // }
}

.events {
  padding-top: 0;

  img {
    width: 100%;
  }

  h3 {
    text-align: center;
  }

  .toVisitTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }
}

.contactPage {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .contactPageTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }

  .contactUs {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
    margin-top: 1rem;

    p {
      color: #960018;
      font-family: "Poppins", Sans-serif;
      font-weight: 800;
      margin-top: 0.5rem;
      margin-bottom: 0.2rem;
    }

    b {
      a {
        color: black;
      }
    }

    .contactUsTitle {
      margin-bottom: 1.5rem;
      h2 {
        color: #960018;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        font-size: 30px;
      }
    }

    .contactUsItems {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .phoneNumber {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        margin-bottom: 1rem;

        .phoneIcon {
          padding: 1rem;
          border-style: solid;
          border-radius: 50%;
          border-width: 0.3rem;
          border-color: #960018;
        }

        .phoneText {
          padding-left: 2rem;

          p {
            font-size: 23px;
          }

          a {
            font-size: px;
          }
        }
      }

      .email {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        margin-bottom: 1rem;

        .mailIcon {
          padding: 1rem;
          border-style: solid;
          border-radius: 50%;
          border-width: 0.3rem;
          border-color: #960018;
        }

        .mailText {
          padding-left: 1rem;

          a {
            font-size: 18px;
            color: #960018;
          }
        }
      }
    }
  }
}

.galleryPage {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .galleryTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #960018;
    h1 {
      color: #ffff;
    }
  }

  .images {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 85%;
  }
}

@import "tablet";
