@media only screen and (min-width: 975px) {
  .homePageNav {
    padding: 0 2rem;
    justify-content: space-between;
    a {
      padding: 1rem 1rem;
      display: inline;
    }
    img {
      margin-left: 5rem;
    }
    .mobileMenuOpenButton {
      display: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    justify-content: space-between;

    .footerItem:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        padding: 0.2rem;
      }
    }

    .footerItem:nth-child(3) {
      display: flex;
      flex-direction: column;
      // align-items: center;
    }

    .footerItem {
      padding: 0 1rem;
      width: 33.33%;
      // align-items: center;
      .locationFooter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h2 {
          font-size: 1rem;
          font-weight: 500;
          margin: 1rem 1rem;
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .socialNetworks {
        margin-left: 3.5rem;
      }
    }
    h1 {
      // margin: 1rem auto;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }
  }

  .googleMaps {
    iframe {
      height: 200px;
      width: 400px;
    }
  }

  .whereWeAre {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .title {
      margin-right: 3rem;

      h2 {
        color: #ffffff;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        font-size: 28px;
      }

      h3 {
        color: #ffffff;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        font-size: 40px;
      }
    }
  }

  .homePage {
    .aboutUs {
      p {
        text-align: justify;
      }
    }

    .droneVideo {
      display: flex;
      flex-direction: row;
      margin: auto;
      justify-content: center;
      align-items: center;
      max-width: 800px;

      h2 {
        font-family: "Poppins", Serif;
        font-weight: 1000;
        font-size: 50px;
        margin-right: 3rem;
      }

      p {
        color: #0c0c0c;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.8em;
        letter-spacing: 0.1px;
        display: inline;
      }

      video {
        width: 320px;
        height: 240px;
        margin-left: 3rem;
      }
    }

    .homePageGalleryWrapper {
      background-color: #fff;
      padding-top: 2rem;

      .homePageGallery {
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        background-color: #fff;

        .homePageGalleryMobile {
          display: none;
        }

        .homePageGalleryText {
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          margin-left: 3rem;
        }

        h2 {
          color: #960018;
          font-family: "Poppins", Sans-serif;
          font-weight: 800;
          padding-top: 1rem;
          font-size: 50px;
          display: inline;
        }

        p {
          display: inline;
        }
      }
    }

    .galleryRedirectWrapper {
      background-color: #fff;

      .galleryRedirect {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 2rem auto;
        max-width: 800px;
        align-items: center;

        .restaurantGallery {
          width: 35%;

          p {
            font-size: 22px;
            letter-spacing: 1px;
          }
        }

        .roomsGallery {
          width: 35%;

          p {
            font-size: 22px;
            letter-spacing: 1px;
          }
        }

        .mobileGalleryText {
          display: none;
        }

        .galleryText {
          width: 30%;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .contactUs {
      display: flex;
      flex-direction: column;
      max-width: 1440px;
      margin: auto;
      margin-top: 1rem;

      p {
        color: #960018;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
      }

      .contactUsTitle {
        margin-bottom: 0;

        h2 {
          color: #960018;
          font-family: "Poppins", Sans-serif;
          font-weight: 800;
          font-size: 30px;
        }
      }

      .contactUsItems {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .phoneNumber {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem 4rem;

          .phoneIcon {
            padding: 1.5rem;
            border-style: solid;
            border-radius: 50%;
            border-width: 0.3rem;
            border-color: #960018;
          }

          .phoneText {
            padding-left: 0;

            p {
              font-size: 23px;
            }

            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .restaurant {
    .gallery {
      max-width: 55%;
    }
  }

  .rooms {
    .gallery {
      max-width: 55%;
    }
  }

  .galleryPage {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .galleryTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      background-color: #960018;
      h1 {
        color: #ffff;
      }
    }

    .images {
      padding-top: 2rem;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      max-width: 50%;
    }
  }

  .contactPage {
    .contactUs {
      display: flex;
      flex-direction: column;
      max-width: 1440px;
      margin: auto;
      margin-top: 1rem;

      p {
        color: #960018;
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
      }

      .contactUsTitle {
        margin-bottom: 0;

        h2 {
          color: #960018;
          font-family: "Poppins", Sans-serif;
          font-weight: 800;
          font-size: 30px;
        }
      }

      .contactUsItems {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .phoneNumber {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem 4rem;

          .phoneIcon {
            padding: 1.5rem;
            border-style: solid;
            border-radius: 50%;
            border-width: 0.3rem;
            border-color: #960018;
          }

          .phoneText {
            padding-left: 0;

            p {
              font-size: 23px;
            }

            a {
              font-size: 18px;
            }
          }
        }

        .email {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem 4rem;

          .mailIcon {
            padding: 1.5rem;
            border-style: solid;
            border-radius: 50%;
            border-width: 0.3rem;
            border-color: #960018;
          }

          .mailText {
            padding-left: 0;
            padding-top: 1rem;

            p {
              font-size: 23px;
            }

            a {
              font-size: 20px;
              color: #960018;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .homePage {
    .galleryRedirect {
      .restaurantGallery {
        width: 270px;
      }

      .roomsGallery {
        width: 270px;
      }
    }

    .droneVideo {
      video {
        width: 90%;
      }
    }
  }

  .contactPage {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .contactPageTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      background-color: #960018;
      h1 {
        color: #ffff;
      }
    }

    .contactUs {
      .contactUsItems {
        .phoneNumber {
          .phoneIcon {
            padding: 0.8rem;
          }

          .phoneText {
            padding-left: 3rem;

            a {
              font-size: px;
            }
          }
        }

        .email {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.3rem;
          margin-bottom: 1rem;

          .mailIcon {
            padding: 0.8rem;
            border-style: solid;
            border-radius: 50%;
            border-width: 0.3rem;
            border-color: #960018;
          }

          .mailText {
            padding-left: 0.8rem;

            a {
              font-size: 14px;
              color: #960018;
            }
          }
        }
      }
    }
  }

  .googleMaps {
    iframe {
      height: 230px;
      width: 300px;
    }
  }
}
